import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './Auth.service';
@Injectable({ providedIn: 'root' })
export class AuthGurdService implements CanActivate {
  constructor(private _authService: AuthService,private _router:Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this._authService.IsLoggedIn())
    {
      return true;
    }
    else
    {
      this._router.navigate(['/account/login'],{ queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
