import { Location } from '@angular/common';
import {  Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import { AppInitializerService } from './app-initializer.service';
import { AuthService } from './shared/services/Auth.service';
import { SpinnerService } from './shared/services/spinner.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isShowSpinner = false;
  isAppInitalized = false;
  constructor(
    private sppinnerService: SpinnerService,
    appInitializerService: AppInitializerService,
  ) {
    this.isAppInitalized = appInitializerService.initialized;
    this.sppinnerService.getHttpStatus().subscribe((req: boolean) => {
      this.isShowSpinner = req;
   });
  }

  ngOnInit(): void {

  }

}
