// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hostUrl: `http://localhost:4200/`,
  backendBaseUrl: `https://localhost:44308/`,
  ApiUrl: `http://alifarag1-001-site1.atempurl.com/api/`,
  GoogleMapApiKey: `AIzaSyAz3gwwFXslDA0_FPRKbGO4zk8p-CEP1m0`,
  firebase: {
    apiKey: "AIzaSyAjk9tZ3WFUuGeKTuqjdhrtlH0UQe17c_I",
    authDomain: "makers-301308.firebaseapp.com",
    projectId: "makers-301308",
    storageBucket: "makers-301308.appspot.com",
    messagingSenderId: "555334666024",
    appId: "1:555334666024:web:faa3527930ab3a5edeb21d",
    measurementId: "G-S0LFJN0WRV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
