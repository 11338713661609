import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './shared/services/Auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

constructor(private authservice:AuthService) { }
    public initialized = false;

    public init() {
        return new Promise((resolve, reject) => {
            this.authservice.refreshLoginData().subscribe(
            (responseData: any) => {
                    this.initialized = true;
                    resolve(true);
                    return;
            },
            (error: any) => {
              if(error.status == 0)
              {
                this.initialized = false;
                resolve(error);
                return;
              }
              this.initialized = true;
              resolve(true);
             // return Observable.throw(error || 'Server error');
          });
        });
    }
}
