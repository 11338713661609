<div class="mt-5 mb-5">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-11">

              <div class="text-center">
                  <svg id="Layer_1" class="svg-computer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424.2 424.2">
                      <style>
                          .st0{fill:none;stroke: #ffffff;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                      </style>
                      <g id="Layer_2">
                          <path class="st0" d="M339.7 289h-323c-2.8 0-5-2.2-5-5V55.5c0-2.8 2.2-5 5-5h323c2.8 0 5 2.2 5 5V284c0 2.7-2.2 5-5 5z"></path>
                          <path class="st0" d="M26.1 64.9h304.6v189.6H26.1zM137.9 288.5l-3.2 33.5h92.6l-4.4-33M56.1 332.6h244.5l24.3 41.1H34.5zM340.7 373.7s-.6-29.8 35.9-30.2c36.5-.4 35.9 30.2 35.9 30.2h-71.8z"></path>
                          <path class="st0" d="M114.2 82.8v153.3h147V82.8zM261.2 91.1h-147"></path>
                          <path class="st0" d="M124.5 105.7h61.8v38.7h-61.8zM196.6 170.2H249v51.7h-52.4zM196.6 105.7H249M196.6 118.6H249M196.6 131.5H249M196.6 144.4H249M124.5 157.3H249M124.5 170.2h62.2M124.5 183.2h62.2M124.5 196.1h62.2M124.5 209h62.2M124.5 221.9h62.2"></path>
                      </g>
                  </svg>
                  <h3 class="mt-4 text-white"> النظام غير متاح حاليا</h3>
                  <p class="text-white-50">
                      لا تقلق نواجه بعض المشاكل مع النظام  ...  يحدث لأفضل منا
                       وسيتم تفعيل النظام في اقرب وقت.</p>

                  <div class="row mt-5">
                      <div class="col-md-4">
                          <div class="text-center mt-3 ps-1 pe-1">
                              <div class="avatar-md rounded-circle  mx-auto">
                                <span style="color: #6c757d;">
                                  <i class="fas fa-question-circle fa-4x "></i>
                                </span>
                              </div>
                              <h5 class="text-uppercase mt-3 text-white">لماذا الموقع معطل؟</h5>
                              <p class="text-white-50">ربما يكون السبب هو انقطاع اتصالك بالانترنت او تواجه مشكله في الاتصال بالخادم.</p>
                          </div>
                      </div> <!-- end col-->
                      <div class="col-md-4">
                          <div class="text-center mt-3 ps-1 pe-1">
                            <div class="avatar-md rounded-circle  mx-auto">
                              <span style="color: #6c757d;">
                                <i class="fas fa-clock fa-4x "></i>
                              </span>
                            </div>
                              <h5 class="text-uppercase mt-3 text-white">ما هي فترة التوقف؟</h5>
                              <p class="text-white-50">فريقنا التقني يعمل على حل المشكله وسيعود النظام للعمل في اقرب وقت ممكن.</p>
                          </div>
                      </div> <!-- end col-->
                      <div class="col-md-4">
                          <div class="text-center mt-3 ps-1 pe-1">
                            <div class="avatar-md rounded-circle  mx-auto">
                              <span style="color: #6c757d;">
                                <i class="fas fa-headset fa-4x "></i>
                              </span>
                            </div>
                              <h5 class="text-uppercase mt-3 text-white">هل تحتاج الى دعم؟</h5>
                              <p class="text-white-50">يمكنك التواصل معنا عبر البريد الالكتروني.. <br> <a href="mailto:#" class="text-white-50 fw-bold">no-reply@Makers-app.com</a></p>
                          </div>
                      </div> <!-- end col-->
                  </div> <!-- end row-->
              </div> <!-- end /.text-center-->

          </div> <!-- end col -->
      </div>
      <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- <div class="auth-fluid">
  <div class="auth-fluid-form-box pt-1 pb-1">
    <div class="align-items-center d-flex h-100">
      <div class="card-body">

        <a  class="d-block text-center">
          <span class="text-center">
            <img src="../assets/images/logo-dark1.png" alt="" height="100">
          </span>
        </a>

        <div class="row justify-content-center">
          <div class="col-12">
            <div class="error-text-box text-center align-items-center d-flex justify-content-center">
              <h1 class="text-danger">500</h1>
            </div>
            <div class="text-center">
              <h3 class="mt-0 mb-2"> ! النظام غير متاح حاليا </h3>
              <p class="text-muted mb-3">
               لا تقلق نواجه بعض المشاكل مع النظام  ...  يحدث لأفضل منا
                وسيتم تفعيل النظام في اقرب وقت ممكن
                يمكنك التواصل معنا عن طريق البريد الالكتروني </p>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div> -->
