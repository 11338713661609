import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { promise } from 'protractor';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggedInUserDataModel } from '../models/logged-in-user-data-model';
import { GenericServiceResponse, ServiceResponse } from '../models/serviceResponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseurl: string = environment.ApiUrl;
  private MonitoringTimer;
  private LoginDataItemName = 'loginData';
  private FcmTokenItemName = 'fcm_device_token';
  private browserIdItemName = 'browserId';
  private LoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private permissionsService: NgxPermissionsService,private _http: HttpClient) {
    this.refreshLoggedInSubject();

    // if intially logged in setup timer to monitor LoggedInStatus
    if (this.LoggedInSubject.value == true) {
      this.MonitoringTimer = setInterval(this.refreshLoggedInSubject, 10);
    }
    this.LoggedInStatusMonitor();
  }

  // this function called once in  constructor to  LoggedInSubject subscribtion
  private LoggedInStatusMonitor() {
    let lastLoggedInStatus = this.LoggedInSubject.value;
    this.LoggedInSubject.subscribe(currentloggedInStatus => {
      // check if login  state changed
      const loggedInStatusChanged  = currentloggedInStatus != lastLoggedInStatus

      //  if login state changed and user not loggedIn anymore => reload app
      if (loggedInStatusChanged && currentloggedInStatus == false)
        return  window.location.reload();

      // if not logged in => clear MonitoringTimer
      else if (currentloggedInStatus == false  && this.MonitoringTimer)
       clearInterval(this.MonitoringTimer);

      // if  logged in => setup MonitoringTimer if not already setuped
      else if (currentloggedInStatus && !this.MonitoringTimer)
        this.MonitoringTimer = setInterval(this.refreshLoggedInSubject, 10);

      // update lastLoggedInStatus from current state
      lastLoggedInStatus = currentloggedInStatus;
    })


  }

  private refreshLoggedInSubject = () => {
    let loginData = this.getAuthTokenData();
    if (loginData != null) {
      let tokenExpireDate = new Date(loginData.expiresDate);
      let currentDate = new Date();
      let isOverDueDate: boolean = currentDate >= tokenExpireDate;
      if (!isOverDueDate) {
        if (this.LoggedInSubject.value != true)
          this.LoggedInSubject.next(true);
      }
      else {
        if (this.LoggedInSubject.value != false)
          this.LoggedInSubject.next(false);
        this.removeAuthToken();
      }
    }
    else {
      if (this.LoggedInSubject.value != false)
        this.LoggedInSubject.next(false);
    }
  }

  private setAuthToken(loginResult: LoggedInUserDataModel) {
    localStorage.setItem(this.LoginDataItemName, JSON.stringify(loginResult));
    this.refreshUserPermissions();
    this.refreshLoggedInSubject();
  }

  private removeAuthToken() {
    localStorage.removeItem(this.LoginDataItemName);
    this.refreshUserPermissions();
    this.refreshLoggedInSubject();
  }

  private refreshUserPermissions()
  {
    this.permissionsService.flushPermissions();
    const loggedInUserData = this.getAuthTokenData();
    if(loggedInUserData)
      this.permissionsService.loadPermissions(loggedInUserData.permissions);
    console.log(`current user permissions :${JSON.stringify(this.permissionsService.getPermissions())}`);
  }

  IsLoggedIn() {
    this.refreshLoggedInSubject();
    return this.LoggedInSubject.value;
  }
  LoggedInStatusSubject() {
    return this.LoggedInSubject;
  }

  login(eMailOrPhoneNumber: string, password: string,recaptcha:string) {
    var loginData = { eMailOrPhoneNumber: eMailOrPhoneNumber, password: password,recaptcha:recaptcha };
    return this._http.post<GenericServiceResponse<LoggedInUserDataModel>>(`${this.baseurl}Account/AdminAuthenticate`, loginData,
      {
        headers: new HttpHeaders()
          .set('content-Type', 'application/json; charset=utf-8')
          .set('accept-language', 'ar')
      }
    ).pipe(map(response => {
      if (response.isSuccess) {
        this.setAuthToken(response.responseData);
      }
      return response;
    }));
  }
  logout() {
    var fcmDeviceToken = this.getFcmDeviceToken();
    this.removeAuthToken();
    return this._http.post<any>(this.baseurl + `Account/Logout`, { deviceToken: fcmDeviceToken }).pipe();
  }

  getAuthTokenData(): LoggedInUserDataModel {
    return JSON.parse(localStorage.getItem(this.LoginDataItemName));
  }
  getFcmDeviceToken(): any {
    return localStorage.getItem(this.FcmTokenItemName);
  }
  getBrowserID(): String {
    var storedID = localStorage.getItem(this.browserIdItemName);
    if (!storedID || storedID != this.getAuthTokenData().userID) {
      storedID = this.getAuthTokenData().userID;
      localStorage.setItem(this.browserIdItemName, storedID);
    }
    return storedID;
  }

  refreshLoginData()
{
  if(!this.IsLoggedIn())
       return from([new GenericServiceResponse<LoggedInUserDataModel>()]);
    return this._http.post<GenericServiceResponse<LoggedInUserDataModel>>(`${this.baseurl}Account/RefreshAccessToken`,{},
      {
        headers: new HttpHeaders().set('accept-language','ar'),
      }
    ).pipe(map(response=>{
      if(response.isSuccess)
      {
        this.setAuthToken(response.responseData);
        return response;
      }
      else{
        this.removeAuthToken();
        return response;
      }
    }));
  }
}
