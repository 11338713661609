import { environment } from 'src/environments/environment';
import { AgmCoreModule, MarkerManager } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Custom User Definitions
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthGurdService } from './shared/services/authGurd.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './shared/services/http.interceptor.service';
import { SpinnerService } from './shared/services/spinner.service';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AppInitializerService } from './app-initializer.service';
import { ErrorComponent } from './error/error.component';
import { NgProgressModule, } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

const RxJS_Services = [HttpConfigInterceptor, SpinnerService];
// Use a global subject (whether this violates best practices in your case is up to you).
// This is just to point out a more simple way of achieving a lazy-loaded config.

@NgModule({
  declarations: [	AppComponent,
      ErrorComponent
   ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      autoDismiss: true,
      preventDuplicates: true,
      timeOut: 10000
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.GoogleMapApiKey
    }),
    AngularFireModule.initializeApp(environment.firebase),
    NgbModule,
    NgxPermissionsModule.forRoot(),
    NgProgressModule.withConfig({
      color: '#1abc9c'
    }),
    NgProgressHttpModule
  ],
  providers: [
    AuthGurdService,
    //{ provide: LOCALE_ID, useValue: "ar-sa" },
    ...RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
      {
        provide: RECAPTCHA_LANGUAGE,
        useValue: "ar", // use arabic language
      },
      {
        provide: APP_INITIALIZER,
        useFactory: (initalizer: AppInitializerService) =>  function() {return initalizer.init();},
        deps: [AppInitializerService],
        multi: true
      }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
