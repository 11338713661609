import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { observable, Observable, throwError } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { AuthService } from './Auth.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService,private _spinnerService: SpinnerService,private toastrService:ToastrService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.warn("start intercept process")
    request = request.clone({
      headers: request.headers.set('Accept-Language', 'ar'),
    });
    const getTokenData = this._authService.getAuthTokenData();
    if (getTokenData) {
      const token = getTokenData.token;
      if (token != undefined || token != null) {
        request.headers.set('Authorization', 'Bearer ' + token);
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });
      }
    }

    this._spinnerService.setHttpStatus(true);
    return next.handle(request).pipe(
      map((event) => {
        // console.log('we Got an response ');
       if (event instanceof HttpResponse) {
            //console.log('event--->>>', event);
            let response = event;
            if(response && response.body.responseCode == 400)
            {
              console.warn('validation Error While Requesting API (400) :'+JSON.stringify(response.body));
              let errorsMessags = '' ;
              for(let error of response.body.validationErrors)
              {
                errorsMessags +=  '-'+ error.field +' : '+error.message+'<br>';
              }
              this.toastrService.warning(errorsMessags,' يرجى تعديل البيانات التاليه');
            }


        }
        this._spinnerService.setHttpStatus(false);
        return event;
      }),
      catchError((response) => {
        if(response.status == 403)
        {
           console.error('forbidden  Requesting API (403) :'+JSON.stringify(response.body));
           this.toastrService.error("ليس لديك صلاحية لتنفيذ هده الطلب");
        }
        else if(response.status == 0)
        {
            console.error('Error While Requesting API :'+JSON.stringify(response));
            this.toastrService.error("لا يمكن الوصول للخادم يرجى التأكد من اتصالك بالانترنت");
        }
        else
        {
            console.error('Error While Requesting API :'+JSON.stringify(response));
            this.toastrService.error(response && response.error && response.error.responseMessage ? response.error.responseMessage : response.message,response.statusText);
        }
        this._spinnerService.setHttpStatus(false);
        return throwError(response);;
      }),
      finalize(() => {
        // reques has been done
      })
    );
  }
}
