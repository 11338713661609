export class ServiceResponse {
  exception: any;
  hasValidationErrors: boolean;
  isSuccess: boolean;
  responseCode:StatusCode;
  responseCodeText:string;
  responseData:any;
  responseMessage:string;
  validationErrors:any;
}

export class GenericServiceResponse<T>  {
  exception: any;
  hasValidationErrors: boolean;
  isSuccess: boolean;
  responseCode:StatusCode;
  responseCodeText:string;
  responseData:T;
  responseMessage:string;
  validationErrors:any;
}

export enum StatusCode {
  Success = 200,
  ValidationErrors = 400,
  Failed = 500,
}




